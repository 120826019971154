import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProductContext } from "../../components/Store/CartContext";

const TopNav = () => {
  const context = useContext(ProductContext);

  // Helper function to safely get cart items
  const getCartItems = () => {
    if (!context) return null;
    if (typeof context.cart !== 'function') return null;
    const cartData = context.cart();
    if (!cartData) return null;
    return cartData.items;
  };

  return (
    <div>
      <nav style={navStyle}>
        <div>
          <Link to="/">
            <img
              style={logoStyle}
              src="https://assets.bash.com/cms/sterns_store_logo_3e9888bd10.png"
              alt="Sterns"
              className="header__logo"
            />
          </Link>
        </div>
        <div style={iconsStyle}>
          <a href="https://bash.com/store-finder">
            <i className="icon icon-map" style={iconStyle}></i>
          </a>

          <Link
            to="/cart"
            className="header__control-item header__control-item--alt js-header-trigger-bag"
          >
            <i className="icon icon-shoppingcart" style={iconStyle}></i>
            <div className="header__control-label">
              {getCartItems() && (
                <div
                  className="header__control-item-counter js-component-header-mini-cart-count"
                  data-value="mini-cart"
                >
                  {getCartItems()}
                </div>
              )}
            </div>
          </Link>
        </div>
      </nav>
    </div>
  );
};

const navStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "1180px",
  margin: "0 auto",
  padding: "16px 0"
};

const logoStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const iconsStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginRight: "10px",
  color: "#000"
};

const iconStyle = {
  fontSize: "24px",
  margin: "12px 10px"
};

export default TopNav;